<template>
	<artistProfileLayout>
		<div class="label_profile" >
			<!-- Head Section -->
			<div class="head_profile">
				<div v-if="headBackground" class="profile_head_background" v-bind:style="{'background-image': 'url('+(headBackground)+')'}"></div>
				<div v-else class="profile_head_background default"></div>

				<v-container>
					<div class="record_label_data">
						<!-- Remove duplicate skeleton loader -->
						<recordLabelProfileAvatar 
							ref="recordLabelProfileAvatar"
							:labelID="RECORD_LABEL.id"
							:isOwner="isOwnerLabel"
							:isLoading="loader"
							@image-updated="handleImageUpdated">
						</recordLabelProfileAvatar>

						<div class="label_info">
							<!-- Add loading state for label name and follows -->
							<template v-if="loader">
								<div class="label-name-skeleton skeleton-loader"></div>
								<div class="followers-skeleton skeleton-loader"></div>
							</template>
							<template v-else>
								<h2 class="label_name">
									<template>{{ RECORD_LABEL.title }}</template>
								</h2>
								<div class="follows" v-if="RECORD_LABEL">
									<span class="count">{{ followersCount }}</span>
									<div>Followers</div>
								</div>
							</template>
						</div>
					</div>
				</v-container>
			</div>

			<!-- Content Section -->
			<div class="wrap_profile_music_events">
				<div class="head_profile_music_events">
					<v-container>
						<div class="head_content">
							<!-- Tabs -->
							<v-tabs background-color="transparent" color="#fff" v-model="tabs">
								<v-tab v-for="tab in tabsSection" :key="tab.index">{{ tab.name }}</v-tab>
							</v-tabs>

							<!-- Actions -->
							<div class="actions">
								<!-- Initial loading state -->
								<template v-if="loader || actionButtonsLoader">
									<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-1'"></div>
									<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-2'"></div>
									<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-3'"></div>
								</template>

								<!-- Loaded state -->
								<template v-else>
									<!-- Follow Button -->
									<template v-if="AUTH_DATA.accessToken">
										<template v-if="followBtnLoader">
											<div class="skeleton-loader action-button-skeleton"></div>
										</template>
										<template v-else>
											<button type="button" class="follow_btn" v-if="!isFollow" @click="follow(RECORD_LABEL.id)">
												<span class="icon"></span>
												Follow
											</button>
											<button type="button" class="follow_btn following" v-else 
													@click="unfollow(RECORD_LABEL.id)"
													@touchend="hoverFollowingButton = false"
													@mouseover="hoverFollowingButton = true"
													@mouseleave="hoverFollowingButton = false">
												<span class="icon"></span>
												<template v-if="hoverFollowingButton === true">Unfollow</template>
												<template v-else>Following</template>
											</button>
										</template>
									</template>
									<template v-else>
										<button type="button" class="follow_btn" @click="showNeedLoginDialog = true">
											<span class="icon"></span>
											Follow
										</button>
									</template>

									<!-- Message Button -->
									<button type="button" class="message_btn" 
											@click="AUTH_DATA.accessToken ? showComingSoonDialog = true : showNeedLoginDialog = true">
										<span class="icon"></span>
										Message
									</button>

									<!-- Share Button -->
									<button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
										<span class="icon"></span>
										Share
									</button>
									<button v-if="copiedArtistLink" type="button" class="share_btn">
										Copied!
									</button>
								</template>
							</div>
						</div>
					</v-container>
				</div>

				<!-- Add back the tab content -->
				<v-tabs-items v-model="tabs">
					<!-- Music Tab -->
					<v-tab-item :transition="false">
						<div class="music_section artist_releases">
							<div class="label_releases">
								<div class="container">
									<div class="content_header">
									</div>

									<div v-if="recordLabelReleasesLoader || loader" class="wrap_music skeleton-loader">
										<albumGridSkeletonLoader v-for="index in 6" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
									</div>
									<div class="wrap_music group" v-if="!recordLabelReleasesLoader && recordLabelReleases && recordLabelReleases.length">
										<template v-for="(group, groupIndex) of groupLabelReleases">
											<div class="item" v-for="(item, itemIndex) in group" :key="`group-${groupIndex}-item-${itemIndex}`">
												<div class="wrap_year">
													<span class="year" v-if="itemIndex === 0">{{ String(Object.keys(group[0])) }}</span>
													<span class="line"></span>
												</div>
												<albumGrid
													v-for="(album, albumIndex) of item"
													:key="`album-${album.id}-${albumIndex}`"
													:album="album"
													:playZone="recordLabelReleases">
												</albumGrid>
											</div>
										</template>
									</div>
									<template v-if="!loader && !recordLabelReleasesLoader && !recordLabelReleases.length">
										<nothingHereYet></nothingHereYet>
									</template>
								</div>
							</div>
						</div>
					</v-tab-item>

					<!-- Events Tab -->
					<v-tab-item :transition="false">
						<div class="events_section">
							<div class="uploaded_events">
								<v-container>
									<div class="content_header">
										<span class="_head_name"></span>
									</div>
									<nothingHereYet></nothingHereYet>
								</v-container>
							</div>
						</div>
					</v-tab-item>

					<!-- About Tab -->
					<v-tab-item :transition="false">
						<div class="about_section">
							<v-container>
								<div class="content_header">
									<span class="_head_name">About</span>
								</div>
								<div class="about_content" v-if="RECORD_LABEL.description">
									<p v-html="formattedBio"></p>
								</div>
								<template v-else>
									<nothingHereYet></nothingHereYet>
								</template>
							</v-container>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>

			<!-- Dialogs -->
			<comingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></comingSoonDialog>
			<dialogNeedLogin v-if="showNeedLoginDialog" v-model="showNeedLoginDialog"></dialogNeedLogin>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
		</div>
	</artistProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import artistProfileLayout from "@/layouts/profiles/artistProfileLayout";
import recordLabelProfileAvatar from "@/components/profile/recordLabelProfileAvatar";
import checkAccessToken from "@/mixins/checkAccessToken";
import isMobile from "@/mixins/isMobile";
import Defer from "@/utils/defer";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader.vue";
import albumGrid from "@/components/musicReleases/albumGrid.vue";
import moment from 'moment';

const comingSoonDialog = () => import('@/components/dialogs/comingSoonDialog');
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
const nothingHereYet = () => import('@/components/small/nothingHereYet');
const errorDialog = () => import('@/components/dialogs/error');

export default {
	name: 'label-profile',
	props: ['id', 'name'],
	components: {
		recordLabelProfileAvatar,
		nothingHereYet, errorDialog,
		albumGrid, albumGridSkeletonLoader,
		artistProfileLayout,
		comingSoonDialog,
		dialogNeedLogin
	},
	mixins: [checkAccessToken, isMobile, Defer()],
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			headBackground: '',
			loader: false,
			editLabel: false,
			changeRecordLabelLoader: false,

			changeData: {
				title: null,
				description: null,
			},

			tabs: null,
			tabsSection: [
				{index: 0, name: 'Music'},
				{index: 1, name: 'Events'},
				{index: 2, name: 'About'}
			],

			actionButtonsLoader: true,
			hoverFollowingButton: false,
			isFollow: false,
			followersCount: null,
			followBtnLoader: false,
			messageBtnLoader: false,
			supportBtnLoader: false,

			showComingSoonDialog: false,
			showNeedLoginDialog: false,
			errorDialog: false,

			shareLink: window.location.href,
			isOwnerLabel: false,
			copiedArtistLink: false,
			groupLabelReleases: [],
			cacheKey: new Date().getTime(),
		}
	},
	watch: {
		RECORD_LABEL: {
			handler() {
				this.getBackground();
			},
			deep: true
		},
		tabs() {
			if (this.tabs !== 2) {
				this.editLabel = false;
			}
		}
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			AUTH_DATA: 'AUTH_DATA',
			RECORD_LABEL: 'RECORD_LABEL',
			RECORD_LABEL_FOLLOWERS: 'RECORD_LABEL_FOLLOWERS',
			recordLabelReleases: 'RECORD_LABEL_RELEASES',
			recordLabelReleasesLoader: 'RECORD_LABEL_RELEASES_LOADER',
			hasFollowedRecordLabel: 'HAS_FOLLOWED_RECORD_LABEL',
		}),
		formattedBio() {
			return this.RECORD_LABEL.description.replace(/\n/g, '<br>');
		}
	},
	created() {
		this.getRecordLabelData();
	},
	mounted() {
		let edit = this.$router.history.current.params.edit;
		if (edit === true) {
			this.editLabelProfile();
		}
	},
	methods: {
		...mapActions({
			getRecordLabel: 'GET_RECORD_LABEL',
			getRecordLabelFollowers: 'GET_RECORD_LABEL_FOLLOWERS',
			followRecordLabel: 'FOLLOW_RECORD_LABEL',
				unfollowRecordLabel: 'UNFOLLOW_RECORD_LABEL',
				changeRecordLabel: 'CHANGE_RECORD_LABEL',
				getRecordLabelReleases: 'GET_RECORD_LABEL_RELEASES',
				getHasFollowedRecordLabel: 'GET_HAS_FOLLOWED_RECORD_LABEL'
		}),
		getBackground() {
			if (this.RECORD_LABEL?.logo?.thumbnail) {
				this.headBackground = `${this.RECORD_LABEL.logo.thumbnail['160x160']}?cache=${this.cacheKey}`;
			}
		},
		getDataLabel() {
			let recordLabel = this.id || this.name;
			this.getRecordLabel(recordLabel)
				.then(() => {
					this.changeData.title = this.RECORD_LABEL.title;
					this.changeData.description = this.RECORD_LABEL.description;
				})
				.catch(err => console.error(err));
		},
		getRecordLabelData() {
			this.loader = true;
			this.actionButtonsLoader = true;
			// const labelNameWithSpaces = this.name.replace(/-/g, ' ');

			let recordLabel = this.id || this.name;
			this.getRecordLabel(recordLabel)
				.then(() => {
					if (!this.RECORD_LABEL.id) {
						this.$router.push({name: "404"});
					}
					this.changeData.title = this.RECORD_LABEL.title;
					this.changeData.description = this.RECORD_LABEL.description;

					this.isOwnerLabel = this.RECORD_LABEL && this.RECORD_LABEL.owner !== null && this.RECORD_LABEL.owner.id === this.userID;
					this.followersCount = this.RECORD_LABEL.followersCount;
					if (this.AUTH_DATA.accessToken) {
						this.getHasFollowedRecordLabel(this.RECORD_LABEL.id)
							.then(() => {
								this.isFollow = this.hasFollowedRecordLabel;
							})
							.catch(err => {
								console.error(`getHasFollowedRecordLabel, ${err}`)
							})
							.finally(() => this.actionButtonsLoader = false);
					} else {
						this.actionButtonsLoader = false;
					}

					this.getRecordLabelReleases(this.RECORD_LABEL.id)
						.then(() => {
							this.groupLabelReleases = [];
							for (let i = 0; i < this.recordLabelReleases.length; i++) {
								const year = moment(this.recordLabelReleases[i].releasedAt).year();
								this.groupLabelReleases[year] = this.groupLabelReleases[year] || [];
								this.groupLabelReleases[year].push({[year]: this.recordLabelReleases[i]});
							}
							this.groupLabelReleases.reverse();
						})
						.catch(err => console.error(`get record-label releases, ${err}`));
				})
				.catch(err => {
					console.error(`get record-label, ${err}`);
					this.$router.push({name: "404"});
				})
				.finally(() => this.loader = false);
		},
		editLabelProfile() {
			this.editLabel = true;
			this.tabs = 2; // aboutSection
		},
		cancel() {
			this.editLabel = false;
			this.tabs = 0; // musicSection
			this.changeData.title = this.RECORD_LABEL.title;
			this.changeData.description = this.RECORD_LABEL.description;
		},

		follow(labelID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.followRecordLabel(labelID)
					.then(result => {
						if (result === true) {
							this.followBtnLoader = false;
							this.isFollow = true;
							this.followersCount = this.RECORD_LABEL.followersCount += 1;
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`follow record-label, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.showNeedLoginDialog = true;
			}
		},
		unfollow(labelID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.unfollowRecordLabel(labelID)
					.then(result => {
						if (result === true) {
							this.followBtnLoader = false;
							this.isFollow = false;
							if (this.RECORD_LABEL.followersCount >= 0) {
								this.followersCount = this.RECORD_LABEL.followersCount -= 1;
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`unfollow record-label, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);

			} else {
				this.showNeedLoginDialog = true;
			}
		},
		copyArtistLink() {
			navigator.clipboard.writeText(this.shareLink);

			this.copiedArtistLink = true;

			setTimeout(() => {
				this.copiedArtistLink = false;
			}, 2000);
		},
		saveRecordLabel() {
			this.changeRecordLabelLoader = true;
			let params = {
				id: this.RECORD_LABEL.id,
				title: this.changeData.title,
				description: this.changeData.description,
				// address: null
			};
			this.changeRecordLabel(params)
				.then(() => {
					this.getDataLabel();
					this.editLabel = false;
				})
				.catch(err => {
					this.errorDialog = true;
					console.error(`saveRecordLabel, ${err}`)
				})
				.finally(() => this.changeRecordLabelLoader = false);
		},
		updateCacheKey(newKey) {
			this.cacheKey = newKey;
			this.refreshBackground();
		},
		refreshBackground(newCacheKey) {
			const cacheKey = newCacheKey || new Date().getTime();
			this.cacheKey = cacheKey;

			if (this.RECORD_LABEL?.logo?.thumbnail) {
				this.headBackground = `${this.RECORD_LABEL.logo.thumbnail['160x160']}?cache=${cacheKey}`;
				
				// Update avatar component if needed
				if (this.$refs.recordLabelProfileAvatar) {
					this.$refs.recordLabelProfileAvatar.refreshAvatar(cacheKey);
				}
			}
		},
		handleImageUpdated() {
			this.refreshBackground();
		}
	}

}
</script>

<style lang="scss">
.label_info,
.actions {
	.skeleton-loader {
		background: linear-gradient(90deg, #1a2235 25%, #243049 50%, #1a2235 75%);
		background-size: 200% 100%;
		animation: loading 1.5s infinite;
	}

	.label-name-skeleton {
		height: 36px;
		width: 200px;
		margin-bottom: 8px;
		border-radius: 4px;
		@media screen and (max-width: 479px) {
			margin-top: 8px;
		}
	}

	.followers-skeleton {
		height: 24px;
		width: 100px;
		border-radius: 4px;
	}

	.action-button-skeleton {
		height: 34px;
		width: 120px;
		margin-right: 12px;
		display: inline-block;
		vertical-align: middle;
		border-radius: 100px;
	}
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}

footer {
	margin-top: 200px !important;
}
</style>
